<template>
  <div>
    <a-button type="link" @click="toBeiAn">陕ICP备2020014834号</a-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toBeiAn() {
      window.open("https://beian.miit.gov.cn/", "_target");
    },
  },
};
</script>

<style></style>
