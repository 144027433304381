<template>
  <a-dropdown
    v-if="currentUser && currentUser.name"
    placement="bottomCenter"
    style="float: right; margin-right: 30px"
  >
    <span class="ant-pro-account-avatar">
      <a-icon type="user" style="margin-right: 5px" />
      <span>{{ currentUser.name }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-menu-item key="personal" @click="toUser">
          <a-icon type="user" />
          个人中心
        </a-menu-item>
        <a-menu-item key="logout" @click="handleLogout">
          <a-icon type="logout" />
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { Modal } from "ant-design-vue";
import store from "@/store";
export default {
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    toUser() {
      this.$router.push("/user_manage/user_home");
    },
    handleLogout() {
      Modal.confirm({
        title: "信息",
        content: "您确定要注销吗?",
        onOk: () => {
          store.dispatch("handleLogout").then(() => {
            this.$message.success("注销成功!");
            this.$router.push("/user");
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
