<template>
  <div>
    <a-layout id="components-layout-demo-side" style="min-height: 100vh">
      <a-layout-sider
        :trigger="null"
        :theme="navTheme"
        v-model="collapsed"
        collapsible
        :width="layout_width"
      >
        <div class="logo">天眼</div>
        <SideMenu
          :theme="navTheme"
          :collapsed="collapsed"
          :layout_width="layout_width"
        />
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <a-icon
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            class="trigger"
            @click="collapsed = !collapsed"
          >
          </a-icon>
          <Header :current-user="currentUser" />
        </a-layout-header>
        <a-layout-content style="margin: 0 16px">
          <router-view></router-view>
        </a-layout-content>
        <a-layout-footer style="text-align: center">
          <Footer />
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import Header from "./header";
import Footer from "./footer";
import SideMenu from "./sidemenu";
import { mapState } from "vuex";
export default {
  data() {
    return {
      navTheme: "dark",
      collapsed: false,
      layout_width: "180px",
      currentUser: {},
    };
  },
  computed: {
    ...mapState({
      username: (state) => state.user.userName,
    }),
  },
  components: {
    Header,
    Footer,
    SideMenu,
  },
  mounted() {
    setTimeout(() => {
      this.currentUser = {
        name: this.username,
      };
    }, 1500);
  },
};
</script>

<style scoped>
.trigger {
  padding: 0 20px;
  line-height: 64px;
  font-size: 20px;
}
.trigger:hover {
  background: #eeeeee;
}
.logo {
  height: 64px;
  line-height: 64px;
  text-align: center;
  color: #ffffff;
}
</style>
>
